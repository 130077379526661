@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Kalnia:wght@100..700&family=Quicksand:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Satisfy&display=swap');

html, body {
    height: 100%;
    margin: 0;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* La hauteur de l'application prendra 100% de la hauteur de la fenêtre */
}


.cgu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10% 5% ;
    color: rgb(128, 104, 70);
}

.part-title {
    color: rgb(218, 158, 77);
    font-family: "Kalnia", serif;
}



.cgu h2 {
    font-family: "Quicksand", sans-serif;
}

.cgu p {
    font-family: "Roboto", sans-serif;
}
.header {
    height: 90vh;
    background-image: linear-gradient(
            rgb(128, 104, 70),
            rgba(255, 205, 132, 0.3)
    ), url('https://www.vivre-orleans.fr/wp-content/uploads/2018/11/Statue-Jeanne-DArc.jpg');
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.classic-header {
    height: 50vh;
    background-image: linear-gradient(
            rgb(128, 104, 70),
            rgba(255, 205, 132, 0.3)
    ), url('https://www.vivre-orleans.fr/wp-content/uploads/2018/11/Statue-Jeanne-DArc.jpg');
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    border-bottom: 3px solid rgb(218, 158, 77);
}


.classic-header .title {
    top: 0;
    z-index: 1;
    left: 0;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 15px;
}
.countdown .element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2f2e54;
    color: white;
    padding: 35px;
    border-radius: 25px;
    font-size: 2rem;
}
.countdown .element p {
    color: white;
}
.classic-header .title div {
    text-align: center;
    color: #f0f0f0;
    width: 100%;
    font-family: "Kalnia", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    line-height: 1.2;
    font-variation-settings: "wdth" 100;

    font-size: 3em;
    text-shadow: 0 0 10px rgba(255, 205, 132, 0.3);
}
.header .title {
    top: 0;
    z-index: 1;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.part-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
}
.part-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    overflow: hidden;
}
.mobile-pict {
    display: none;
}
.post-part-img {
    background-color: white;
    width: 50%;
    height: 50%;
    object-fit: scale-down;
}
.part-card .left-part, .right-part {
    background: rgb(128, 104, 70);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    justify-content: center;

}
.part-card .right-part {
    background: white !important;
    display: flex!important;
    justify-content: center!important;
    align-items: center !important;
}

.part-card p {
    padding: 2% 10%;
    font-family: 'QuickSand';
    text-align: justify;
    color: white;
}
.header .title div {
    text-align: center;
    color: #f0f0f0;
    width: 50%;

    font-family: "Kalnia", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    line-height: 1.2;
    font-variation-settings: "wdth" 100;

    font-size: 3em;
    text-shadow: 0 0 10px rgba(255, 205, 132, 0.3);
}

.header-navbar ul:last-child {
    display: none;
}

.header-part {
    position: absolute;
    width: 65%;
    left: 50%;
    transform: translateX(-50%);
    top: 80vh;
    background: #f0f0f0;
    border: 3px solid rgb(218, 158, 77);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 5px;
}


.header-part h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 1em;
    color: rgb(128, 104, 70);
    text-align: center;
    margin-bottom: 20px;
}

.header-navbar {
    margin-top: 5vh;
    z-index: 5;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header-navbar ul li a {
    text-decoration: none;
    color: #f0f0f0;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.2em;
    font-weight: normal;
}

.header-navbar ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.header-navbar ul li {
    z-index: 5;
    margin: 0 10px;
}

.main-content {
    min-height: 20vh;
    flex-grow: 1;
    background-color: #f0f0f0; /* Ajoutez votre couleur de fond souhaitée */

}

.footer {
    height: 250px;
    position: relative;
    background-color: rgba(12, 12, 12, 0.91); /* Ajoutez votre couleur de fond souhaitée */
    display: flex;
    color: white;
    font-family: 'Quicksand', sans-serif;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.section-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 20px;
}

.section-content .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(128, 104, 70);
    margin: 0;
    border-radius: 10px;
    padding: 0;
}

.left h1 {
    font-family: 'Quicksand', sans-serif;
    color: white;
    font-size: 5rem;
    margin: 0;
    padding: 0;
}

.left p {
    font-family: 'Quicksand', sans-serif;
    color: white;
    font-size: 1rem;
    margin: 0 15%;
    padding: 0;
}

.contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    height: 250px;
    padding: 20px;
}

.contact .contact-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    margin: 0;
    border-radius: 10px;
    padding: 0;
}

center h1 {
    font-family: 'Quicksand', sans-serif;
    color: #f0f0f0;
    font-size: 2.5rem;

    margin: 20px 0;
    padding: 0;
}

.section-content .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact button {
    background-color: rgb(175, 137, 8);
    color: white;
    padding: 10px 20px;
    border: none;
    width: 90%;
    height: 40%;
    margin: 5px 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-family: 'Quicksand', sans-serif;
    cursor: pointer;
    transition: 0.3s;
}

.contact div:first-child h1, p {
    margin: 0;
    padding: 0;
    font-family: "Kalnia", serif;
    font-optical-sizing: auto;
    color: rgb(65, 54, 38);
}

.contact button:last-child {
    background-color: transparent;
    color: rgba(28, 26, 26, 0.91);
    border: 2px dotted rgba(28, 26, 26, 0.91);
}

.responsive-menu {
    display: none;
}

button {
    background: none;
    border:none;
    cursor: pointer;
    color: white;
}


.footer a {
    margin: 5px;
    font-size: 2em;
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.miage-explain {
    width: 60%;
}

.partner-logo {
    width: 100px;
    height: 100px;
    object-fit: scale-down;
}
a.cgu-link {
    font-size: 0.9rem!important;
    color: rgba(192, 147, 76, 0.8);
    cursor: pointer;
}

.countdown-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    gap: 15px;
}
@media (max-width: 768px) {

    .header {
        height: 70vh;
        padding: 0;
        margin: 0;
    }


    .header .header-navbar ul:first-child {
        display: none;
    }

    .header .header-navbar div {
        width: 100%;
    }

    .header .header-navbar ul:last-child {
        display: flex;
        justify-content: space-around;
    }

    .header .header-navbar ul:last-child li i {
        font-size: 2rem;
    }


    .section-content .left {
        display: none;
    }
    div.main-content {
        min-height: 0 !important;
    }
    .section-content h1 {
        font-size: 2em;
        margin: 15px;
    }

    .left p {
        margin: 0 5%;
    }

    .contact div {
    }

    .contact {
        gap: 25px;
        height: auto;
    }

    .section-content {
        margin: 15px;
        padding: 0;
    }

    .section-content .image {
        display: none;
    }

    .header .title div {
        font-size: 2em;
        width: 90%;
    }

    .miage-explain-title {
        font-size: 2em;
    }

    .cta-explain {
        margin-top: 25px !important;
    }

    .miage-paragraph {
        padding: 5% !important;
        margin: 0 !important;
    }

    .miage-explain {
        font-size: 1rem;
        width: 100%;
    }

    .header-part {
        border-right-width: 0;
        border-left-width: 0;
        border-bottom-width: 0;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
        width: 100%;
        padding: 0;
        border-radius: 0;
        top: 60vh;
    }

    .partner-logo {
        width: 100px;
        height: 100px;
    }

    .main-content {
        width: 100%;
    }

    .header-navbar ul li a {
        font-size: 1em;
    }

    .section-content {
        grid-template-columns: 1fr;
    }

    .contact {
        display: flex;
        flex-direction: column;
    }

    .footer {
        text-align: center;
    }

    .contact div {
        margin-top: 0;
        padding: 8px;
    }

    .mobile-pict {
        display: block;
    }
    .mobile-pict .mobile-pict-card {
        position: relative;
        width: 100%;
    }
    .mobile-pict .mobile-pict-card div {
        position: absolute;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: rgba(12, 12, 12, 0.59);
    }

    .mobile-pict .mobile-pict-card div h1 {
        color: rgb(255, 187, 89);
    }
    .mobile-pict .mobile-pict-card img {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
    .contact div {
        padding: 5px;
        text-align: center;
    }

    .footer a {
        font-size: 1.5em;
    }

    .part-card {
        display: grid;
        grid-template-columns: 1fr;

        width: 100%;
        overflow: hidden;
    }

    .countdown .element {
        font-size: 1.3rem;
        height: 15px;
        width: 15px;
    }

    .responsive-menu {
        display: none;
        background-color: white;
        text-decoration: none;
        color: white;
        position: relative;
        z-index: 3;


    }
    .responsive-menu ul,li {
        list-style: none;

        padding: 0;
        margin: 0;
    }
    .responsive-menu ul li {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .responsive-menu ul,li  a{
        text-decoration: none;
        font-family: "Quicksand", sans-serif;
        color: rgb(63, 42, 12);;
    }
    .header-navbar {
    }

    .part-card {
        overflow: scroll;
    }
    .right-part, .left-part {
        height: 420px !important;
    }
    .part-card p {
        padding: 1% 5% !important;
    }
}
